import axios from 'axios'
import getMessage from './getMessage'
import getKey from './getKey'

import SnackbarUtils from 'utils/SnackbarUtils'
import history from 'utils/history'

const getRawToken = () => {
  if (!localStorage.token) return null
  const token = JSON.parse(localStorage.token)
  return token.jwt
}

export default {
  setupInterceptors: (instance = axios) => {
    instance.interceptors.request.use(config => {
      config.headers['Authorization'] = `Bearer ${getRawToken()}`
      return config
    })

    instance.interceptors.response.use(
      response => {
        const message = getMessage(response)
        const key = getKey(response)

        if (message && key) {
          SnackbarUtils.success(message)
        }

        return Promise.resolve(response)
      },
      error => {
        const message = getMessage(error.response, false)
        const key = getKey(error.response)

        if (message && key) {
          SnackbarUtils.error(message)
        }

        if (error.response.status === 401) {
          history.push('/session/sign-out')
        }

        return Promise.reject(error)
      }
    )
  },
}
