export const yellow = {
  50: 'hsl(45, 100%, 96%)', //  #FFFAEB
  100: 'hsl(45, 90%, 88%)', //  #FCEFC7
  200: 'hsl(45, 86%, 81%)', //  #F8E3A3
  300: 'hsl(43, 90%, 76%)', //  #F9DA8B
  400: 'hsl(43, 89%, 70%)', //  #F7D070
  500: 'hsl(42, 78%, 60%)', //  #E9B949
  600: 'hsl(42, 63%, 48%)', //  #C99A2E
  700: 'hsl(43, 72%, 37%)', //  #A27C1A
  800: 'hsl(43, 77%, 27%)', //  #7C5E10
  900: 'hsl(43, 86%, 17%)', //  #513C06

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const yellowVivid = {
  50: 'hsl(49, 100%, 96%)', // #FFFBEA
  100: 'hsl(48, 100%, 88%)', // #FFF3C4
  200: 'hsl(48, 95%, 76%)', // #FCE588
  300: 'hsl(48, 94%, 68%)', // #FADB5F
  400: 'hsl(44, 92%, 63%)', // #F7C948
  500: 'hsl(42, 87%, 55%)', // #F0B429
  600: 'hsl(36, 77%, 49%)', // #DE911D
  700: 'hsl(29, 80%, 44%)', // #CB6E17
  800: 'hsl(22, 82%, 39%)', // #B44D12
  900: 'hsl(15, 86%, 30%)', // #8D2B0B

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
