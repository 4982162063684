const dev = {
  apiGateway: { URL: '' },
  environment: 'DEVELOPMENT',
}

const stage = {
  apiGateway: { URL: 'https://beta-api.egrid.app' },
  environment: 'STAGING',
}

const prod = {
  apiGateway: { URL: 'https://api.egrid.app' },
  environment: '',
}

const config =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.NODE_ENV === 'production'
      ? stage
      : dev

export default {
  ...config,
}
