export const lightShadows = [
  'none',
  '0 1px 3px rgba(0,0,0,.12);0 1px 2px rgba(0,0,0,.24)',
  '0 3px 6px rgba(0,0,0,.15);0 2px 4px rgba(0,0,0,.12)',
  '0 10px 20px rgba(0,0,0,.15);0 3px 6px rgba(0,0,0,.10)',
  '0 15px 25px rgba(0,0,0,.15);0 5px 10px rgba(0,0,0,.05)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
  '0 20px 40px rgba(0,0,0,.2)',
]

export const darkShadows = [
  'none',
  '0 1px 3px rgba(0,0,0,.12);0 1px 2px rgba(0,0,0,.24)',
  '0 3px 6px rgba(0,0,0,.15);0 2px 4px rgba(0,0,0,.12)',
  '0 10px 20px rgba(0,0,0,.15);0 3px 6px rgba(0,0,0,.10)',
  '0 15px 25px rgba(0,0,0,.15);0 5px 10px rgba(0,0,0,.05)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
  '0 20px 40px rgba(0,0,0,.5)',
]

// Flat shadow
//   '3px 3px 0 1px rgba(0,0,0,.15)',

// Examples of double-shadows
//   '0 1px 3px rgba(0,0,0,.12);0 1px 2px rgba(0,0,0,.24)',
//   '0 3px 6px rgba(0,0,0,.15);0 2px 4px rgba(0,0,0,.12)',
//   '0 10px 20px rgba(0,0,0,.15);0 3px 6px rgba(0,0,0,.10)',
//   '0 15px 25px rgba(0,0,0,.15);0 5px 10px rgba(0,0,0,.05)',
//   '0 20px 40px rgba(0,0,0,.2)',
