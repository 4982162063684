import instance from 'api'

export const fetchUser = async () =>
  instance.get('/user').then(({ data }) => data)

export const updateUser = async ({ user }) =>
  instance
    .patch('/user', { user })
    .then(response => response)
    .then(({ data }) => data)

export const updateProfileImage = async user =>
  instance
    .patch('/user', user)
    .then(response => response)
    .then(({ data }) => data)

export const fetchWorkspaces = async () =>
  instance.get('/user/workspaces').then(({ data }) => data)
