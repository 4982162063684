import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import BeatLoader from 'react-spinners/BeatLoader'
import { css } from '@emotion/core'
import { useTheme, makeStyles } from '@material-ui/core/styles'

const getStyles = ({ display }) => css`
  display: ${display};
`
const useStyles = makeStyles(() => ({
  fullscreen: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export const Loader = ({
  size = 12,
  loading,
  display = 'block',
  fullscreen = false,
}) => {
  const theme = useTheme()
  const styles = getStyles({ display })
  const classes = useStyles()
  return (
    <div className={clsx(fullscreen && classes.fullscreen)}>
      <BeatLoader
        size={size}
        css={styles}
        color={theme.palette.primary.main}
        loading={loading}
      />
    </div>
  )
}

Loader.propTypes = {
  display: PropTypes.string,
  fullscreen: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.number,
}

const getButtonLoaderStyles = option =>
  ({
    center: css`
      position: absolute;
      margin-top: 4px;
    `,
    right: css`
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -12px;
    `,
    left: css`
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -12px;
    `,
  }[option])

export const ButtonLoader = ({ align = 'center' }) => {
  const theme = useTheme()
  const styles = React.useMemo(() => getButtonLoaderStyles(align), [align])
  return (
    <BeatLoader
      size={10}
      css={styles}
      color={theme.palette.secondary.main}
      loading={true}
    />
  )
}

ButtonLoader.propTypes = {
  align: PropTypes.oneOf(['right', 'center', 'left']),
}
