export const blue = {
  50: 'hsl(205, 76%, 92%)', // '#DCEEFB'
  100: 'hsl(205, 97%, 85%)', // '#B6E0FE'
  200: 'hsl(205, 84%, 74%)', // '#84C5F4'
  300: 'hsl(205, 74%, 65%)', // '#62B0E8'
  400: 'hsl(205, 65%, 55%)', // '#4098D7'
  500: 'hsl(205, 67%, 45%)', // '#2680C2'
  600: 'hsl(205, 76%, 39%)', // '#186FAF'
  700: 'hsl(205, 82%, 33%)', // '#0F609B'
  800: 'hsl(205, 87%, 29%)', // '#0A558C'
  900: 'hsl(205, 100%, 21%)', // '#003E6B'

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const blueVivid = {
  50: 'hsl(202, 100%, 95%)', // #E6F6FF
  100: 'hsl(204, 100%, 86%)', // #BAE3FF
  200: 'hsl(206, 93%, 73%)', // #7CC4FA
  300: 'hsl(208, 88%, 62%)', // #47A3F3
  400: 'hsl(210, 83%, 53%)', // #2186EB
  500: 'hsl(212, 92%, 43%)', // #0967D2
  600: 'hsl(214, 95%, 36%)', // #0552B5
  700: 'hsl(215, 96%, 32%)', // #03449E
  800: 'hsl(216, 98%, 25%)', // #01337D
  900: 'hsl(218, 100%, 17%)', // #002159

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
