import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { SnackbarProvider as NotistackProvider } from 'notistack'
import { SnackbarUtilsConfigurator } from 'utils/SnackbarUtils'

const useStyles = makeStyles(theme => ({
  snackbar: {
    '& button': { color: 'inherit' },
  },
  success: {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.getContrastText(theme.palette.success.light),
  },
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.getContrastText(theme.palette.error.light),
  },
  warning: {
    backgroundColor: theme.palette.warning.light,
    color: theme.palette.getContrastText(theme.palette.warning.light),
  },
  info: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.getContrastText(theme.palette.info.light),
  },
}))

export const SnackbarProvider = ({ children }) => {
  const classes = useStyles()

  return (
    <NotistackProvider
      preventDuplicate
      maxSnack={4}
      classes={{
        variantSuccess: classNames(classes.snackbar, classes.success),
        variantError: classNames(classes.snackbar, classes.error),
        variantWarning: classNames(classes.snackbar, classes.warning),
        variantInfo: classNames(classes.snackbar, classes.info),
      }}>
      <SnackbarUtilsConfigurator />
      {children}
    </NotistackProvider>
  )
}

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
