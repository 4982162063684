import { useMutation, queryCache } from 'react-query'
import { updateProfileImage } from 'api/clients/userClient'

export default ({
  onMutate = () => {},
  onSuccess = () => {},
  onError = () => {},
  onSettled = () => {},
} = {}) =>
  useMutation(updateProfileImage, {
    onSuccess: user => {
      queryCache.setQueryData('user', user)
      if (typeof onSuccess === 'function') return onSuccess(user)
    },
    onError: error => {
      if (typeof onError === 'function') return onError(error)
    },
    onMutate,
    onSettled,
  })
