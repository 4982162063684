import React from 'react'
import 'App.css'

import { Route, Router, Switch } from 'react-router-dom'
import {
  Backoffice,
  Conta,
  Perfil,
  Recover,
  Session,
  SignUp,
  Workspaces,
  Workspace,
} from 'loadable'

import PrivateRoute from 'components/PrivateRoute'
import history from 'utils/history'

const App = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route path='/session' component={Session} />
        <Route path='/sign-up' component={SignUp} />
        <Route path='/recover' component={Recover} />
        <PrivateRoute exact path='/' component={Workspaces} />
        <PrivateRoute path='/conta' component={Conta} />
        <PrivateRoute path='/perfil' component={Perfil} />
        <PrivateRoute path='/backoffice' component={Backoffice} />
        <PrivateRoute path='/:workspaceUrl' component={Workspace} />
      </Switch>
    </Router>
  )
}

export default App
