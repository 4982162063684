import React from 'react'
import PropTypes from 'prop-types'
import { ReactQueryConfigProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

const queryConfig = {}

export const QueryConfigProvider = ({ children }) => (
  <ReactQueryConfigProvider config={queryConfig}>
    {children}
    <ReactQueryDevtools initialIsOpen={false} />
  </ReactQueryConfigProvider>
)

QueryConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
