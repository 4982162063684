// eslint-disable-next-line no-unused-vars
export const props = theme => ({
  MuiFilledInput: {
    disableUnderline: true,
  },
  MuiButton: {
    disableElevation: true,
  },
  MuiMenu: {
    MenuListProps: {
      disablePadding: true,
    },
  },
})
