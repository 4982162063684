const routeSlicer = (response, begin, end = undefined) =>
  response.request.responseURL.split('/').slice(begin, end).join('/')

const getMessage = (response, success = true) => {
  const { status, data } = response

  if (response.config.method === 'get' && success) return ''

  const isLogin = routeSlicer(response, -1) === 'user_token'
  const isRecover = routeSlicer(response, -2) === 'password/recover'
  const isPassword = routeSlicer(response, -2) === 'password/edit'
  const isFetch = routeSlicer(response, -3).startsWith(
    'medicoes/horarias/fetch'
  )
  const isUserConfirmation = routeSlicer(response, -3).startsWith(
    'sign_up/confirm'
  )
  const isVerifyCertificate = response?.request?.responseURL?.endsWith(
    'verify_certificate'
  )

  switch (status) {
    case 200:
      if (isUserConfirmation) return 'Email confirmado com sucesso.'
      return 'Operação realizada com sucesso.'
    case 201: {
      if (isLogin) return 'Login realizado com sucesso.'
      return 'Registro criado com sucesso.'
    }
    case 204: {
      if (isRecover) return 'Instruções foram enviadas para o e-mail informado.'
      if (isPassword) return 'Senha alterada com sucesso.'
      if (isFetch)
        return 'Coleta agendada com sucesso. Aguarde o retorno por e-mail.'
      return 'Operação realizada com sucesso.'
    }
    case 401:
      return 'Sua sessão expirou. Entre novamente com suas credenciais.'
    case 403:
      return 'Você não possui permissão para esta operação.'
    case 404: {
      if (isLogin) return 'E-mail e/ou senha inválido(s).'
      return 'O recurso solicitado não foi encontrado.'
    }
    case 422:
      if (isUserConfirmation) return data.base
      if (isVerifyCertificate) return ''
      return 'Não foi possível salvar.'
    case 500:
      return 'Um erro interno ocorreu no servidor.'
    default:
      return 'Um erro desconhecido ocorreu.'
  }
}

export default getMessage
