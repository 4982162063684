import axios from 'axios'
import config from '../config'

const defaultHeaders = {
  Accept: 'application/vnd.egrid.v1+json',
  'Content-Type': 'application/json',
}

export default axios.create({
  baseURL: config.apiGateway.URL,
  headers: defaultHeaders,
})
