export const red = {
  50: 'hsl(360, 100%, 97%)', // FFEEEE
  100: 'hsl(360, 82%, 89%)', // #FACDCD
  200: 'hsl(360, 77%, 78%)', // #F29B9B
  300: 'hsl(360, 71%, 66%)', // #E66A6A
  400: 'hsl(360, 64%, 55%)', // #D64545
  500: 'hsl(360, 67%, 44%)', // #BA2525
  600: 'hsl(360, 72%, 38%)', // #A61B1B
  700: 'hsl(360, 79%, 32%)', // #911111
  800: 'hsl(360, 85%, 25%)', // #780A0A
  900: 'hsl(360, 92%, 20%)', // #610404

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const redVivid = {
  50: 'hsl(360, 100%, 95%)', // #FFE3E3
  100: 'hsl(360, 100%, 87%)', // #FFBDBD
  200: 'hsl(360, 100%, 80%)', // #FF9B9B
  300: 'hsl(360, 91%, 69%)', // #F86A6A
  400: 'hsl(360, 83%, 62%)', // #EF4E4E
  500: 'hsl(356, 75%, 53%)', // #E12D39
  600: 'hsl(354, 85%, 44%)', // #CF1124
  700: 'hsl(352, 90%, 35%)', // #AB091E
  800: 'hsl(350, 94%, 28%)', // #8A041A
  900: 'hsl(348, 94%, 20%)', // #610316

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
