import React, { Suspense } from 'react'
import { Loader } from 'components/Loader'

const WaitFor = (Component, loaderProps = {}) => props => (
  <Suspense fallback={<Loader {...loaderProps} />}>
    <Component {...props} />
  </Suspense>
)

export default WaitFor
