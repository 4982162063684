export const teal = {
  50: 'hsl(152, 68%, 96%)', // #EFFCF6
  100: 'hsl(154, 75%, 87%)', // #C6F7E2
  200: 'hsl(156, 73%, 74%)', // #8EEDC7
  300: 'hsl(158, 58%, 62%)', // #65D6AD
  400: 'hsl(160, 51%, 49%)', // #3EBD93
  500: 'hsl(162, 63%, 41%)', // #27AB83
  600: 'hsl(164, 71%, 34%)', // #199473
  700: 'hsl(166, 72%, 28%)', // #147D64
  800: 'hsl(168, 80%, 23%)', // #0C6B58
  900: 'hsl(170, 97%, 15%)', // #014D40

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const tealVivid = {
  50: 'hsl(165, 67%, 96%)', // #F0FCF9
  100: 'hsl(163, 75%, 87%)', // #C6F7E9
  200: 'hsl(162, 73%, 74%)', // #8EEDD1
  300: 'hsl(164, 70%, 63%)', // #5FE3C0
  400: 'hsl(166, 64%, 49%)', // #2DCCA7
  500: 'hsl(168, 78%, 41%)', // #17B897
  600: 'hsl(170, 91%, 32%)', // #079A82
  700: 'hsl(172, 94%, 26%)', // #048271
  800: 'hsl(172, 98%, 20%)', // #016457
  900: 'hsl(176, 100%, 13%)', // #004440

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
