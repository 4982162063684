export const pink = {
  50: 'hsl(329, 100%, 94%)', // #FFE0F0
  100: 'hsl(330, 87%, 85%)', // #FAB8D9
  200: 'hsl(330, 77%, 76%)', // #F191C1
  300: 'hsl(330, 72%, 65%)', // #E668A7
  400: 'hsl(330, 66%, 57%)', // #DA4A91
  500: 'hsl(330, 63%, 47%)', // #C42D78
  600: 'hsl(330, 68%, 40%)', // #AD2167
  700: 'hsl(330, 70%, 36%)', // #9B1B5A
  800: 'hsl(331, 74%, 27%)', // #781244
  900: 'hsl(330, 79%, 20%)', // #5C0B33

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const pinkVivid = {
  50: 'hsl(341, 100%, 95%)', // #FFE3EC
  100: 'hsl(338, 100%, 86%)', // #FFB8D2
  200: 'hsl(336, 100%, 77%)', // #FF8CBA
  300: 'hsl(334, 86%, 67%)', // #F364A2
  400: 'hsl(330, 79%, 56%)', // #E8368F
  500: 'hsl(328, 85%, 46%)', // #DA127D
  600: 'hsl(326, 90%, 39%)', // #BC0A6F
  700: 'hsl(324, 93%, 33%)', // #A30664
  800: 'hsl(322, 93%, 27%)', // #870557
  900: 'hsl(320, 100%, 19%)', // #620042

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
