import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import { useAuthState } from 'contexts/AuthContext'
import { useUser } from 'hooks/user'

const UserProvider = ({ children }) => {
  const { data, isAuthenticated } = useAuthState()
  const [state, dispatch] = useReducer(userReducer, { ...data.payload })
  const { data: user, isSuccess } = useUser({
    refetchOnWindowFocus: false,
    enabled: isAuthenticated,
  })

  React.useEffect(() => {
    if (isSuccess) {
      dispatch({ type: 'update', user })
    }
  }, [isSuccess, user])

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

const userReducer = (state, action) => {
  switch (action.type) {
    case 'update': {
      const { user } = action
      return {
        ...state,
        ...user,
      }
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const UserStateContext = React.createContext()

const UserDispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in UserContext.Provider')
})

const useUserState = () => {
  const state = React.useContext(UserStateContext)
  if (state === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return state
}

const useUserDispatch = () => {
  const dispatch = React.useContext(UserDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return dispatch
}

const useUpdateUserState = () => {
  const dispatch = useUserDispatch()
  return React.useCallback(user => dispatch({ type: 'update', user }), [
    dispatch,
  ])
}

UserProvider.propTypes = {
  children: PropTypes.node,
}

export { UserProvider, useUserState, useUpdateUserState }
