import React from 'react'
import PropTypes from 'prop-types'

import { LocalizationProvider } from '@material-ui/pickers'
import DateFnsUtils from '@material-ui/pickers/adapter/date-fns'
import locale from 'date-fns/locale/pt-BR'

export const PickersLocaleProvider = ({ children }) => (
  <LocalizationProvider dateAdapter={DateFnsUtils} locale={locale}>
    {children}
  </LocalizationProvider>
)

PickersLocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
