import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import useLocalStorage from 'hooks/useLocalStorage'

const extractDataFromToken = token => ({
  raw: token.jwt,
  header: JSON.parse(window.atob(token.jwt.split('.')[0])),
  payload: JSON.parse(window.atob(token.jwt.split('.')[1])),
})

const AuthStateContext = React.createContext()

const AuthDispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in AuthContext.Provider')
})

const authReducer = (state, action) => {
  switch (action.type) {
    case 'success': {
      const { token } = action
      return {
        ...state,
        data: extractDataFromToken(token),
        isAuthenticated: true,
      }
    }

    case 'updateProfile': {
      const { profile } = action

      return {
        ...state,
        data: {
          ...state.data,
          payload: {
            ...state.data.payload,
            profile,
          },
        },
      }
    }

    case 'destroy':
      return {
        ...state,
        data: {},
        isAuthenticated: false,
      }

    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const AuthProvider = ({ children }) => {
  const [token] = useLocalStorage('token', null)
  const [state, dispatch] = useReducer(authReducer, {
    data: token ? extractDataFromToken(token) : {},
    isAuthenticated: token ? true : false,
  })
  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

const useAuthState = () => {
  const state = React.useContext(AuthStateContext)
  if (state === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider')
  }
  return state
}

const useAuthDispatch = () => {
  const dispatch = React.useContext(AuthDispatchContext)
  if (dispatch === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider')
  }
  return dispatch
}

const useLogin = () => {
  const dispatch = useAuthDispatch()
  const [, setToken] = useLocalStorage('token', null)
  return React.useCallback(
    token => {
      setToken(token)
      return dispatch({ type: 'success', token })
    },
    [dispatch, setToken]
  )
}

const useLogout = () => {
  const dispatch = useAuthDispatch()
  const [, setToken] = useLocalStorage('token', null)
  return React.useCallback(() => {
    setToken(null)
    return dispatch({ type: 'destroy' })
  }, [dispatch, setToken])
}

const useUpdateProfile = () => {
  const dispatch = useAuthDispatch()
  return React.useCallback(
    profile => dispatch({ type: 'updateProfile', profile }),
    [dispatch]
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node,
}

export { AuthProvider, useAuthState, useLogin, useLogout, useUpdateProfile }
