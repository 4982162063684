import React from 'react'
import PropTypes from 'prop-types'

import deepmerge from 'deepmerge'
import { common } from '@material-ui/core/colors'
import { decorate } from './decorate'
import { getCookie } from 'utils/theme'
import { lightShadows, darkShadows } from 'utils/shadows'
import { ptBR } from '@material-ui/core/locale'
import { useMediaQuery } from '@material-ui/core'

import {
  red,
  redVivid,
  pink,
  pinkVivid,
  indigo,
  blueGrey,
  coolGrey,
} from 'utils/palette'

import {
  ThemeProvider as MuiThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/styles'

const DefaultFamily = [
  '"Nunito"',
  '"Roboto"',
  '"Helvetica"',
  '"Arial"',
  'sans-serif',
].join(',')

const themeInitialOptions = {}

const themeReducer = (state, action) => {
  switch (action.type) {
    case 'CHANGE':
      return {
        ...state,
        paletteType: action.payload.paletteType || state.paletteType,
      }
    default:
      throw new Error(`Unrecognized type ${action.type}`)
  }
}

export function ThemeProvider({ children }) {
  const [themeOptions, dispatch] = React.useReducer(
    themeReducer,
    themeInitialOptions
  )

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)')
  const preferredType = prefersDarkMode ? 'dark' : 'light'
  const { paletteType = preferredType } = themeOptions

  React.useEffect(() => {
    if (process.browser) {
      const nextPaletteType = getCookie('paletteType')

      dispatch({
        type: 'CHANGE',
        payload: { paletteType: nextPaletteType },
      })
    }
  }, [])

  React.useEffect(() => {
    document.cookie = `paletteType=${paletteType};path=/;max-age=31536000`
  }, [paletteType])

  const theme = React.useMemo(() => {
    const light = paletteType === 'light'
    const grey = light ? blueGrey : coolGrey
    const error = light ? red[400] : redVivid[200]
    const primary = light ? indigo[500] : indigo[200]
    const secondary = light ? pink[500] : pinkVivid[200]
    const defaultBackground = light ? grey[50] : grey[900]
    const paperBackground = light ? common.white : grey[800]
    const shadows = light ? lightShadows : darkShadows

    const nextTheme = responsiveFontSizes(
      createMuiTheme(
        {
          palette: {
            type: paletteType,
            primary: {
              main: primary,
            },
            secondary: {
              main: secondary,
            },
            grey,
            error: { main: error },
            background: {
              default: defaultBackground,
              paper: paperBackground,
            },
          },
          typography: {
            fontFamily: DefaultFamily,
            fontWeightBold: 700,
            h1: { fontSize: '2rem', lineHeight: 1.75, fontWeight: 900 },
            h2: { fontSize: '1.75rem', lineHeight: 1.75, fontWeight: 900 },
            h3: { fontSize: '1.125rem', lineHeight: 1.5, fontWeight: 700 },
            h4: { fontSize: '1.125rem', lineHeight: 1.5, fontWeight: 500 },
            h5: { fontSize: '1em', lineHeight: 1.43, fontWeight: 700 },
            h6: { fontSize: '1em', lineHeight: 1.43, fontWeight: 500 },
          },
          shadows: shadows,
          shape: {
            borderRadius: 8,
          },
          mixins: {
            denseToolbar: {
              minHeight: 48,
            },
          },
        },
        ptBR
      )
    )

    return deepmerge(nextTheme, decorate(nextTheme))
  }, [paletteType])

  React.useEffect(() => {
    // Expose the theme as a global variable so people can play with it.
    if (process.browser) {
      window.theme = theme
    }
  }, [theme])

  return (
    <MuiThemeProvider theme={theme}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </MuiThemeProvider>
  )
}

export const DispatchContext = React.createContext(() => {
  throw new Error('Forgot to wrap component in ThemeContext.Provider')
})

export function useChangeTheme() {
  const dispatch = React.useContext(DispatchContext)

  return React.useCallback(
    options => dispatch({ type: 'CHANGE', payload: options }),
    [dispatch]
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
}
