import { lazy } from 'react'
import WaitFor from 'components/WaitFor'

const Backoffice = WaitFor(
  lazy(() => import('domain/Backoffice')),
  { fullscreen: true }
)

const Conta = WaitFor(
  lazy(() => import('domain/Conta')),
  { fullscreen: true }
)

const Perfil = WaitFor(
  lazy(() => import('domain/Perfil')),
  { fullscreen: true }
)

const Workspaces = WaitFor(
  lazy(() => import('domain/Workspaces')),
  { fullscreen: true }
)

const Workspace = WaitFor(
  lazy(() => import('domain/Workspace')),
  { fullscreen: true }
)

const Invitation = WaitFor(lazy(() => import('domain/Invitation')))

const Recover = WaitFor(
  lazy(() => import('domain/Recover')),
  { fullscreen: true }
)

const Session = WaitFor(
  lazy(() => import('domain/Session')),
  { fullscreen: true }
)

const SignUp = WaitFor(
  lazy(() => import('domain/SignUp')),
  { fullscreen: true }
)

export { Backoffice, Invitation, Conta, Perfil, Recover, Session, SignUp, Workspaces, Workspace }
