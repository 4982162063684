import React from 'react'

import { setLocale } from 'yup'
import ptBr from 'utils/yup.locale.pt-br'

setLocale(ptBr)

const LanguageContext = React.createContext()

const LanguageProvider = props => <LanguageContext.Provider {...props} />

export { LanguageProvider }
