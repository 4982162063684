export const blueGrey = {
  50: 'hsl(210, 36%, 96%)', // '#F0F4F8',
  100: 'hsl(212, 33%, 89%)', // '#D9E2EC',
  200: 'hsl(210, 31%, 80%)', // '#BCCCDC',
  300: 'hsl(211, 27%, 70%)', // '#9FB3C8',
  400: 'hsl(209, 23%, 60%)', // '#829AB1',
  500: 'hsl(210, 22%, 49%)', // '#627D98',
  600: 'hsl(209, 28%, 39%)', // '#486581',
  700: 'hsl(209, 34%, 30%)', // '#334E68',
  800: 'hsl(211, 39%, 23%)', // '#243B53',
  900: 'hsl(209, 61%, 16%)', // '#102A43',

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const coolGrey = {
  50: 'hsl(216, 33%, 97%)', //  #F5F7FA
  100: 'hsl(214, 15%, 91%)', //  #E4E7EB
  200: 'hsl(210, 16%, 82%)', //  #CBD2D9
  300: 'hsl(211, 13%, 65%)', //  #9AA5B1
  400: 'hsl(211, 10%, 53%)', //  #7B8794
  500: 'hsl(211, 12%, 43%)', //  #616E7C
  600: 'hsl(209, 14%, 37%)', //  #52606D
  700: 'hsl(209, 18%, 30%)', //  #3E4C59
  800: 'hsl(209, 20%, 25%)', //  #323F4B
  900: 'hsl(210, 24%, 16%)', //  #1F2933

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const grey = {
  50: 'hsl(0, 0%, 97%)', // #F7F7F7
  100: 'hsl(0, 0%, 88%)', // #E1E1E1
  200: 'hsl(0, 0%, 81%)', // #CFCFCF
  300: 'hsl(0, 0%, 69%)', // #B1B1B1
  400: 'hsl(0, 0%, 62%)', // #9E9E9E
  500: 'hsl(0, 0%, 49%)', // #7E7E7E
  600: 'hsl(0, 0%, 38%)', // #626262
  700: 'hsl(0, 0%, 32%)', // #515151
  800: 'hsl(0, 0%, 23%)', // #3B3B3B
  900: 'hsl(0, 0%, 13%)', // #222222

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const warmGrey = {
  50: 'hsl(40, 23%, 97%)', // #FAF9F7
  100: 'hsl(43, 13%, 90%)', // #E8E6E1
  200: 'hsl(40, 15%, 80%)', // #D3CEC4
  300: 'hsl(39, 11%, 69%)', // #B8B2A7
  400: 'hsl(41, 8%, 61%)', // #A39E93
  500: 'hsl(41, 8%, 48%)', // #857F72
  600: 'hsl(41, 9%, 35%)', // #625D52
  700: 'hsl(37, 11%, 28%)', // #504A40
  800: 'hsl(40, 13%, 23%)', // #423D33
  900: 'hsl(42, 15%, 13%)', // #27241D

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
