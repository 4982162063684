// Name approximations generated by http://chir.ag/projects/name-that-color/

import {
  teal,
  tealVivid,
  yellow,
  yellowVivid,
  blue,
  blueVivid,
} from 'utils/palette'

const CEDAR = '#371126'
const JADE = '#00A978'
const DISCO = '#9E1F6C'
const BOSTON_BLUE = '#3D97BA'
const SEA_BUCKTHORN = '#F7A823'
const POMEGRANATE = '#EF4136'

const getCookie = name => {
  const regex = new RegExp(`(?:(?:^|.*;*)${name}*=*([^;]*).*$)|^.*$`)
  return document.cookie.replace(regex, '$1')
}

const getColor = (key, theme) =>
  theme.palette.type === 'light' ? getLightColor(key) : getDarkColor(key)

const getLightColor = key =>
  ({
    success: teal[500],
    warning: yellow[500],
    info: blue[500],
  }[key])

const getDarkColor = key =>
  ({
    success: tealVivid[200],
    warning: yellowVivid[200],
    info: blueVivid[200],
  }[key])

export {
  CEDAR,
  JADE,
  DISCO,
  BOSTON_BLUE,
  SEA_BUCKTHORN,
  POMEGRANATE,
  getCookie,
  getColor,
}
