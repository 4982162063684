import { common } from '@material-ui/core/colors'
import {
  CEDAR,
  JADE,
  DISCO,
  BOSTON_BLUE,
  SEA_BUCKTHORN,
  getColor,
} from 'utils/theme'

export const palette = theme => {
  const success = theme.palette.augmentColor({
    main: getColor('success', theme),
  })

  const warning = theme.palette.augmentColor({
    main: getColor('warning', theme),
  })

  const info = theme.palette.augmentColor({
    main: getColor('info', theme),
  })

  return {
    success,
    warning,
    info,
    logo: {
      background: CEDAR,
      color: theme.palette.type === 'light' ? common.black : common.white,
      blue: BOSTON_BLUE,
      purple: DISCO,
      green: JADE,
      yellow: SEA_BUCKTHORN,
    },
  }
}
