import { fade } from '@material-ui/core/styles'
import { getColor } from 'utils/theme'

export const overrides = theme => {
  const light = theme.palette.type === 'light'

  const grey = theme.palette.grey
  const borderBottom = `1px solid rgba(0, 0, 0, .${light ? 1 : 2})`
  const backgroundColor = light ? grey[50] : grey[700]

  const error = theme.palette.error.main
  const success = getColor('success', theme)
  const warning = getColor('warning', theme)
  const info = getColor('info', theme)

  const MuiButton = {
    MuiButton: {
      root: { fontWeight: 'bold' },
      text: { color: light ? grey[500] : grey[200] },
    },
  }

  const MuiIconButton = {
    MuiIconButton: {
      root: { padding: theme.spacing(1) },
    },
  }

  const MuiCard = {
    MuiCardHeader: {
      root: {
        borderBottom,
      },
      action: {
        marginTop: 'unset',
        marginRight: 'unset',
      },
    },
    MuiCardContent: {
      root: {
        padding: '8px 0',
        '&:last-child': {
          paddingBottom: 0,
        },
      },
    },
  }

  const MuiDialog = {
    MuiDialogTitle: {
      root: {
        padding: 16,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 16,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 16,
        backgroundColor: light ? grey[100] : grey[700],
      },
    },
  }

  const MuiDivider = {
    MuiDivider: {
      root: {
        borderBottom,
        backgroundColor: 'unset',
      },
    },
  }

  const MuiFilledInput = {
    MuiFilledInput: {
      root: {
        border: 'none',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
          backgroundColor,
        },
        '&$focused': {
          backgroundColor: light ? grey[100] : grey[600],
          boxShadow: `${fade(theme.palette.primary.main, 0.5)} 0 0 0 2px`,
        },
        '@media (hover: none)': {
          backgroundColor,
        },
      },
    },
  }

  const MuiLink = {
    MuiLink: {
      root: {
        fontWeight: 'bold',
      },
    },
  }

  const MuiTable = {
    MuiTableBody: {
      root: {
        '& > :last-child > *': {
          borderBottom,
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom,
      },
      head: {
        fontWeight: 'bold',
        color: light ? grey[500] : grey[300],
      },
    },
    MuiTablePagination: {
      root: {
        borderWidth: 0,
      },
    },
  }

  const MuiAlert = {
    MuiAlert: {
      standardWarning: {
        color: warning,
        backgroundColor: fade(warning, 0.1),
        '& $icon': {
          color: warning,
        },
      },
      standardSuccess: {
        color: success,
        backgroundColor: fade(success, 0.1),
        '& $icon': {
          color: success,
        },
      },
      standardInfo: {
        color: info,
        backgroundColor: fade(info, 0.1),
        '& $icon': {
          color: info,
        },
      },
      standardError: {
        color: error,
        backgroundColor: fade(error, 0.1),
        '& $icon': {
          color: error,
        },
      },
    },
  }

  const MuiSkeleton = {
    MuiSkeleton: {
      root: {
        backgroundColor: light ? grey[400] : grey[600],
      },
    },
  }

  const MuiAlertTitle = {
    MuiAlertTitle: {
      root: {
        fontWeight: 700,
      },
    },
  }

  return {
    ...MuiButton,
    ...MuiIconButton,
    ...MuiCard,
    ...MuiDialog,
    ...MuiDivider,
    ...MuiFilledInput,
    ...MuiLink,
    ...MuiTable,
    ...MuiAlert,
    ...MuiSkeleton,
    ...MuiAlertTitle,
  }
}
