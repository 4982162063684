import React from 'react'
import PropTypes from 'prop-types'

import { Route, Redirect } from 'react-router-dom'
import { useAuthState } from 'contexts/AuthContext'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useAuthState()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/session/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
}

export default PrivateRoute
