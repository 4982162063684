export const indigo = {
  50: 'hsl(221, 68%, 93%)', // #E0E8F9
  100: 'hsl(221, 78%, 86%)', // #BED0F7
  200: 'hsl(224, 67%, 76%)', // #98AEEB
  300: 'hsl(225, 57%, 67%)', // #7B93DB
  400: 'hsl(227, 50%, 59%)', // #647ACB
  500: 'hsl(227, 42%, 51%)', // #4C63B6
  600: 'hsl(228, 45%, 45%)', // #4055A8
  700: 'hsl(230, 49%, 41%)', // #35469C
  800: 'hsl(232, 51%, 36%)', // #2D3A8C
  900: 'hsl(234, 62%, 26%)', // #19216C

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}

export const indigoVivid = {
  50: 'hsl(216, 100%, 93%)', // #D9E8FF
  100: 'hsl(216, 100%, 85%)', // #B0D0FF
  200: 'hsl(219, 95%, 76%)', // #88B1FC
  300: 'hsl(222, 81%, 65%)', // #5E8AEE
  400: 'hsl(224, 69%, 54%)', // #3A66DB
  500: 'hsl(223, 71%, 47%)', // #2251CC
  600: 'hsl(228, 74%, 43%)', // #1D3DBF
  700: 'hsl(230, 80%, 38%)', // #132DAD
  800: 'hsl(232, 86%, 32%)', // #0B1D96
  900: 'hsl(234, 90%, 25%)', // #061178

  get A100() {
    return this[600]
  },
  get A200() {
    return this[700]
  },
  get A400() {
    return this[800]
  },
  get A700() {
    return this[900]
  },
}
