import React from 'react'
import PropTypes from 'prop-types'

import NetworkService from 'utils/NetworkService.js'
import instance from 'api'
import { CssBaseline } from '@material-ui/core'

import { AuthProvider } from 'contexts/AuthContext'
import { ThemeProvider } from 'contexts/ThemeContext'
import { LanguageProvider } from 'contexts/LanguageContext'
import { UserProvider } from 'contexts/UserContext'
import { SnackbarProvider } from 'contexts/SnackbarProvider'
import { QueryConfigProvider } from 'contexts/QueryConfigProvider'
import { PickersLocaleProvider } from 'contexts/PickersLocaleProvider'

NetworkService.setupInterceptors(instance)

function AppProviders({ children }) {
  return (
    <QueryConfigProvider>
      <ThemeProvider>
        <LanguageProvider>
          <PickersLocaleProvider>
            <SnackbarProvider>
              <CssBaseline />
              <AuthProvider>
                <UserProvider>{children}</UserProvider>
              </AuthProvider>
            </SnackbarProvider>
          </PickersLocaleProvider>
        </LanguageProvider>
      </ThemeProvider>
    </QueryConfigProvider>
  )
}

AppProviders.propTypes = {
  children: PropTypes.node,
}

export default AppProviders
